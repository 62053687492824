import { Pipe, PipeTransform } from '@angular/core';
import { TransactionGoalRequestType } from '../proto/goal-transaction-enums.pb';

@Pipe({
  name: 'transactionGoalRequestTypeEnum',
})
export class TransactionGoalRequestTypeEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) return '';
    return TransactionGoalRequestType[value];
  }
}
