import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'bp/layout/layout.component';
import { AcceptedRiskGuard } from 'projects/bp-advisor/src/bp/shared/guards/accepted-risk.guard';
import { BpAuthGuard } from 'projects/bp-core/src/lib/services/portal/is-login.guard';
import { EnableChatGuard } from './modules/chat/enable-chat.guard';
// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const bpAppRoutes: Route[] = [
  // Redirect TO dashboard
  { path: '', redirectTo: 'users', pathMatch: 'full' },

  // NO guard - NO sidebar - NO bpstepsheader
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      // Register
      // {
      //   path: 'register',
      //   loadChildren: () =>
      //     import('bp/modules/get-started/get-started.module').then(mod => mod.GetStartedModule),
      // },

      // Auth
      {
        path: 'auth',
        loadChildren: () => import('bp/modules/auth/auth.module').then(mod => mod.AuthModule),
      },

      // Maintenance
      {
        path: 'maintenance',
        loadChildren: () =>
          import('bp/modules/maintenance/maintenance.module').then(m => m.MaintenanceModule),
      },
    ],
  },

  // SÍ guard - si sidebar - NO bpstepsheader
  {
    path: '',
    canActivate: [BpAuthGuard, AcceptedRiskGuard],
    component: LayoutComponent,
    data: {
      layout: 'bp',
    },
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // Dashboard
      {
        path: 'dashboard',
        loadChildren: () =>
          import('bp/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },

      //Chat
      {
        path: 'chat',
        canActivate: [EnableChatGuard],

        loadChildren: () => import('bp/modules/chat/chat.routes'),
      },
      //users
      {
        path: 'users',
        loadChildren: () => import('bp/modules/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'onboarding',
        loadChildren: () => import('bp/modules/onboarding/onboarding.routes'),
      },
      {
        path: 'operations',
        loadChildren: () => import('bp/modules/operations/operations.routes'),
      },
    ],
  },

  // En duda si debe renderizarse
  {
    path: '',
    canActivate: [BpAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'companies',
        loadChildren: () =>
          import('bp/modules/companies/companies.module').then(m => m.CompaniesModule),
      },
    ],
  },
];

export const notMatchRedirectRoutes: Route[] = [
  {
    path: 'redirect-to',
    pathMatch: 'full',
    loadChildren: () =>
      import('bp/modules/redirect/redirect.module').then(mod => mod.RedirectModule),
  },
  { path: '**', redirectTo: 'redirect-to' },
];

export const notMatchRedirectRoutesOld: Route[] = [
  {
    path: 'not-found',
    pathMatch: 'full',
    loadChildren: () => import('bp/modules/error-404/error-404.routes'),
  },
  { path: '**', redirectTo: 'not-found' },
];

//{ path: '**', redirectTo: 'redirect-to', pathMatch: 'full' },
//{ path: '**', redirectTo: '404-not-found' },
