<div bp-goal-detail-card>
  <!-- Header -->
  <div class="flex items-center justify-between mb-6">
    <div bp-title-card>Listado de actividades</div>
    <button
      class="block sm:hidden"
      mat-icon-button
      [color]="hasFilters ? 'primary' : 'secondary'"
      (click)="toggleFilter.emit()"
    >
      <mat-icon class="cursor-pointer">filter_list</mat-icon>
    </button>
  </div>
  <!-- / Header -->

  <!-- Table WEB -->
  <div class="hidden overflow-x-auto sm:flex">
    @if (activities?.length) {
      <table
        @fadeIn
        class="w-full bg-transparent"
        mat-table
        [dataSource]="activitiesDataSource"
        [trackBy]="trackByFn"
      >
        <ng-container matColumnDef="meta" class="font-medium">
          <th mat-header-cell *matHeaderCellDef>
            <span class="text-base font-semibold text-accent"> Meta/cuenta </span>
          </th>
          <td mat-cell *matCellDef="let activity" class="py-2 align-top">
            <div class="flex items-center gap-2 cursor-pointer" (click)="goToGoalFrom(activity)">
              <img
                class="flex-shrink-0 object-cover object-center w-6 h-6 rounded-full"
                [src]="activity.goalFrom.imageSmall"
                [alt]="activity.goalFrom.title"
              />
              <span class="text-base whitespace-nowrap">
                {{ getGoalTitle(activity) }}
              </span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Fecha" class="font-medium">
          <th mat-header-cell *matHeaderCellDef class="">
            <span class="text-base font-semibold text-accent"> Fecha </span>
          </th>
          <td mat-cell *matCellDef="let activity" class="py-2 align-top">
            @if (activity.isProcessed) {
              <span class="text-base whitespace-nowrap">
                {{ activity.date.toDate() | date: 'dd-MM-YYYY' : 'UTC' }}
              </span>
            }
            @if (!activity.isProcessed) {
              <span bp-tag="accent">{{ goalTransactionState[activity.state] }}</span>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="Operación">
          <th mat-header-cell *matHeaderCellDef class="">
            <span class="text-base font-semibold text-accent"> Operación </span>
          </th>
          <td mat-cell *matCellDef="let activity" class="py-2 align-top">
            <span class="text-base whitespace-nowrap">
              {{ activity.type | transactionTypeFormat }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Descripción">
          <th mat-header-cell *matHeaderCellDef class="">
            <span class="text-base font-semibold text-accent"> Descripción </span>
          </th>
          <td mat-cell *matCellDef="let ac" class="py-2 align-top">
            <ng-container
              *ngTemplateOutlet="descriptionActivity; context: { ac: ac }"
            ></ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="Monto">
          <th class="text-right" mat-header-cell *matHeaderCellDef>
            <span class="block text-base font-semibold text-accent"> Monto </span>
          </th>
          <td class="py-2 text-right align-top" mat-cell *matCellDef="let activity">
            <span
              class="text-base whitespace-nowrap"
              [ngClass]="getAmountClass(activity.amount.value)"
            >
              {{ activity.amount.value | customCurrency: activity?.currency : 2 }}
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="activitiesTableColumns"></tr>
        <tr
          class="h-16 order-row"
          mat-row
          *matRowDef="let row; columns: activitiesTableColumns"
        ></tr>
      </table>
    }
  </div>
  <!-- / Table WEB -->
  <!-- Table MOBILE -->
  <div class="flex sm:hidden">
    @if (activitiesDataSource) {
      <table mat-table [dataSource]="activitiesDataSource" multiTemplateDataRows>
        <ng-container matColumnDef="expand">
          <th mat-header-cell class="w-12 px-0" *matHeaderCellDef aria-label="row actions">
            &nbsp;
          </th>
          <td mat-cell *matCellDef="let element" class="w-12 px-0">
            <button
              mat-icon-button
              aria-label="expand row"
              (click)="
                expandedElement = expandedElement === element ? null : element;
                $event.stopPropagation()
              "
            >
              @if (expandedElement !== element) {
                <mat-icon>keyboard_arrow_down</mat-icon>
              }
              @if (expandedElement === element) {
                <mat-icon>keyboard_arrow_up</mat-icon>
              }
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Operación" class="font-medium">
          <th mat-header-cell *matHeaderCellDef class="pl-0">
            <span class="font-semibold text-accent"> Operación </span>
          </th>
          <td mat-cell *matCellDef="let activity" class="px-0 py-2">
            <div class="flex flex-col gap-1">
              @if (!currentGoal) {
                <div
                  class="flex items-center gap-1 cursor-pointer"
                  (click)="goToGoalFrom(activity)"
                >
                  <span
                    class="overflow-hidden text-base max-w-[146px] overflow-ellipsis whitespace-nowrap"
                  >
                    {{ getGoalTitle(activity) }}
                  </span>
                  <img
                    class="flex-shrink-0 object-cover object-center w-6 h-6 rounded-full"
                    [src]="activity.goalFrom.imageSmall"
                    [alt]="activity.goalFrom.title"
                  />
                </div>
              }
              <span>
                {{ activity.type | transactionTypeFormat }}
              </span>
              @if (activity.isProcessed) {
                <span class="text-base whitespace-nowrap" bp-text-values-card>
                  {{ activity.date.toDate() | date: 'dd-MM-YYYY' : 'UTC' }}
                </span>
              }
              @if (!activity.isProcessed && !activity.isPartial) {
                <span class="self-start" bp-tag="accent">En proceso</span>
              }
              @if (activity.isPartial) {
                <span class="self-start" bp-tag="accent">En proceso</span>
              }
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Monto" class="font-medium">
          <th mat-header-cell *matHeaderCellDef class="px-0 text-right max-w-max">
            <span class="font-semibold text-accent"> Monto </span>
          </th>
          <td mat-cell *matCellDef="let activity" class="px-0 py-2 text-right align-top max-w-max">
            <span
              class="text-base whitespace-nowrap"
              [ngClass]="getAmountClass(activity.amount.value)"
            >
              {{ activity.amount.value | customCurrency: activity?.currency : 2 }}
            </span>
          </td>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td
            class="border-t-0 border-b-0"
            mat-cell
            *matCellDef="let ac"
            [attr.colspan]="columnsToDisplayWithExpand.length"
          >
            <div
              class="border-t-0 border-b-0"
              [@expandCollapse]="ac == expandedElement ? 'expanded' : 'collapsed'"
            >
              <div class="pt-2 pl-[30px]">
                <ng-container
                  *ngTemplateOutlet="descriptionActivity; context: { ac: ac }"
                ></ng-container>
              </div>
              <!-- <div class="flex flex-row px-0 pt-2 mx-0 border-t">
                  <p class="w-full px-0 py-0 my-0">
                    <strong *ngIf="!econsultGoal; else itsEconsultGoal">
                      {{ fundingItem.funding?.mname }} ({{ fundingItem.funding?.mnemonic }})
                    </strong>
                    <ng-template #itsEconsultGoal>
                      <strong>
                        {{ fundingItem.funding?.mname }}
                      </strong>
                    </ng-template>
                  </p>
                </div>
                <div class="flex-row px-0 py-2 mx-0">
                  <p class="flex-col px-0 py-0 my-0">
                    {{ fundingItem.funding?.description }}
                  </p>
                </div>
                <div class="pb-0"></div>
                <p
                  class="flex flex-row my-1"
              *ngFor="
                let feature of fundingItem.funding?.features?.length! > 0
                  ? fundingItem.funding?.features
                  : fundingItem.funding?.featuresjson
              "
                  >
                  <span class="col-auto mr-2">
                    <i class="fas fa-check text-primary"></i>
                  </span>
                  <span class="px-0 col-10">
                    <span class="underline">{{ getFeaturesSplit(feature, 0) }}</span>
                    :
                    <span>{{ getFeaturesSplit(feature, 1) }}</span>
                  </span>
                </p> -->
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplayWithExpand"
          class="cursor-pointer"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="h-0"></tr>
      </table>
    }
  </div>
  <!-- / Table MOBILE -->
  <!-- Table replacement -->
  @if (activities?.length === 0) {
    <div class="flex flex-col items-center justify-center text-base h-60">
      <span bp-title-card> No hay actividad para este periodo </span>
      <span bp-text-values-card> Ajusta los filtros para encontrar transacciones. </span>
    </div>
  }
  <!-- / Table replacement -->

  <!-- Table skeleton -->
  @if (!activities) {
    <div class="flex flex-col gap-4">
      <div
        class="flex-shrink-0 max-w-full bg-gray-200 rounded-full h-7 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 rounded-full h-[1px] max-w-full bg-gray-200 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 max-w-full bg-gray-200 rounded-full h-7 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 rounded-full h-[1px] max-w-full bg-gray-200 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 max-w-full bg-gray-200 rounded-full h-7 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 rounded-full h-[1px] max-w-full bg-gray-200 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 max-w-full bg-gray-200 rounded-full h-7 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 rounded-full h-[1px] max-w-full bg-gray-200 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 max-w-full bg-gray-200 rounded-full h-7 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 rounded-full h-[1px] max-w-full bg-gray-200 dark:bg-gray-700 animate-pulse"
      ></div>
      <div
        class="flex-shrink-0 max-w-full bg-gray-200 rounded-full h-7 dark:bg-gray-700 animate-pulse"
      ></div>
    </div>
  }
  <!-- / Table skeleton -->

  <!-- Footer -->
  <div
    class="flex items-center justify-end"
    [ngClass]="{ 'lg:justify-end': !showExportToExcel, 'lg:justify-between': showExportToExcel }"
  >
    @if (showExportToExcel) {
      <button
        class="hidden text-base lg:block"
        [disabled]="activities?.length === 0"
        mat-stroked-button
        (click)="onExportToExcel()"
      >
        Exportar a excel
      </button>
    }

    <mat-paginator class="text-base" [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
  </div>
  <!-- / Footer -->
</div>

<ng-template #detail>
  @if (activity.reasonTransaction !== '') {
    <div class="pt-2">Razón: {{ activity.reasonTransaction }}</div>
  }

  <div
    class="grid w-full grid-cols-2 gap-2 pt-2"
    [ngClass]="
      activity.type === goalTransactionType.DIVIDEND
        ? 'grid-cols-2'
        : 'grid-cols-[1fr,1fr,1fr,1.5fr]'
    "
  >
    <!-- Template para cuando NO es "DIVIDEND" -->

    <!-- Template para cuando es "DIVIDEND" -->

    <!-- Seleccionar el template correcto basado en activity.type -->
    @if (activity.type === goalTransactionType.DIVIDEND) {
      <span class="font-semibold text-left">Fondo</span>
      <span class="font-semibold text-right">Monto</span>
      <mat-divider class="col-span-2"></mat-divider>
      @for (ac of getGoalTransactionFundings(activity); track ac) {
        <span class="text-left">{{ getMnemonic(activity, ac) }}</span>
        <span class="text-right">{{ ac.amount | customCurrency: ac?.currency : 2 }}</span>
      }
      <!-- Totales -->
      <ng-container>
        <mat-divider class="col-span-2"></mat-divider>
        <ng-container>
          <span class="font-medium text-left">Monto total</span>
          <span class="font-medium text-right">{{
            activity.amount?.value | customCurrency: activity?.currency : 2
          }}</span>
        </ng-container>
      </ng-container>
    } @else {
      <!-- Encabezados -->
      @if (activity.goalFrom?.goalCategory?.code !== 'caja-moneda') {
        <span class="font-semibold text-left">Fondo</span>
        <span class="font-semibold text-right">Cuotas</span>
      }
      @if (activity.goalFrom?.goalCategory?.code === 'caja-moneda') {
        <span class="font-semibold text-left">Moneda</span>
        <span class="font-semibold text-right">Cantidad</span>
      }
      <span class="font-semibold text-right">Precio</span>
      <span class="font-semibold text-right">Monto</span>
      <mat-divider class="col-span-4"></mat-divider>
      @for (ac of getGoalTransactionFundings(activity); track ac) {
        <span class="text-left">{{ getMnemonic(activity, ac) }}</span>
        <span class="text-right">{{
          ac.quotas % 1 === 0
            ? getAbsoluteValue(ac.quotas)
            : (getAbsoluteValue(ac.quotas) | number: '1.2-2')
        }}</span>
        <span class="text-right">{{ ac.price | customCurrency: ac?.currency : 2 }}</span>
        <span class="text-right">
          {{ ac.amount | customCurrency: ac?.currency : 2 }}
        </span>
      }
      <!-- Totales -->
      <ng-container>
        <!-- Caja -->
        @if (getBox(activity); as box) {
          <span class="text-left">Caja</span>
          <span class="col-start-4 text-right">{{
            box.amount | customCurrency: activity?.currency : 2
          }}</span>
        }
        <!-- Comision broker -->
        @if (activity.cost?.value! > 0) {
          <mat-divider class="col-span-4"></mat-divider>
          <span class="col-span-2 text-left">Comisión broker</span>
          <span class="col-start-4 text-right">{{
            activity.cost?.value | customCurrency: activity?.currency : 2
          }}</span>
        }
        <mat-divider class="col-span-4"></mat-divider>
        <ng-container>
          <span class="col-span-2 font-medium text-left">Monto total</span>
          <span class="col-start-4 font-medium text-right">{{
            activity.amount?.value | customCurrency: activity?.currency : 2
          }}</span>
        </ng-container>
      </ng-container>
    }
  </div>
</ng-template>

<ng-template #descriptionActivity let-ac="ac">
  <div class="flex flex-col text-base">
    <!-- Depositos -->
    @if (ac.type === goalTransactionType.BUY || ac.type == goalTransactionType.DEPOSIT) {
      <span>{{ ac.paymentMethod | paymentMethodFormat }}</span>
    }

    <!-- Transferencias entre meta  -->
    @if (
      ac.type === goalTransactionType.TRANSFER_GOAL_SALE ||
      ac.type === goalTransactionType.TRANSFER_GOAL_BUY
    ) {
      @if (ac.type === goalTransactionType.TRANSFER_GOAL_SALE) {
        <span>Hacia {{ ac.goalTo.title }}</span>
      }
      @if (ac.type === goalTransactionType.TRANSFER_GOAL_BUY) {
        <span>Desde {{ ac.goalFrom.title }}</span>
      }
    }

    <!-- Retiros -->
    @if ((ac.type | transactionTypeFormat) === 'Retiro') {
      @if (ac.userBankAccount) {
        <span>
          {{ ac.userBankAccount?.bank | removeDashes | titlecase }} ******{{
            ac.userBankAccount?.accountNumber.slice(-4)
          }}
        </span>
      }
      @if (!ac.userBankAccount) {
        <span>
          {{ ac.paymentMethod | paymentMethodFormat }}
        </span>
      }
    }

    <!-- Cambio de portafolio  -->
    @if (
      ac.type === goalTransactionType.CHANGE_PORTFOLIO_BUY ||
      ac.type === goalTransactionType.CHANGE_PORTFOLIO_SALE
    ) {
      @if (ac.portfolioFrom && ac.type === goalTransactionType.CHANGE_PORTFOLIO_SALE) {
        <span>Desde {{ ac.portfolioFrom?.title }}</span>
      }
      @if (ac.portfolioTo && ac.type === goalTransactionType.CHANGE_PORTFOLIO_BUY) {
        <span>Hacia {{ ac.portfolioTo?.title }}</span>
      }
      <!-- Si no hay portfolios -->
      @if (!ac.portfolioFrom && !ac.portfolioTo) {
        <span>Sin información</span>
      }
    }

    <!-- Pago de dividendos -->
    @if (ac.type === goalTransactionType.DIVIDEND) {
      <span>Pago de dividendos</span>
    }

    <div (click)="showDetail(ac, detail)" bp-link>Ver detalle</div>
  </div>
</ng-template>
